export const DAYS = [
  'Пн',
  'Вв',
  'Ср',
  'Чт',
  'Пт',
  'Сб'
];

export const TIME_POINTS = [
  '08:30',
  '10:25',
  '12:20',
  '14:15',
  '16:10',
  '18:30',
  '20:20',
];
